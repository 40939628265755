import dayjs from "dayjs";

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function ellipsisMiddle(text, suffixCount) {
  return text.length > suffixCount ? text.slice(0, suffixCount).trim() + "..." : text;
}

export function requestParamsGetIcon(sys, iconType, value, color) {
  return {
    sys: sys || 1,
    iconType: iconType.trim(),
    value: value || 1 || 2 || 3 || 4,
    color: color || "black" || "white",
  };
}

export function randomNumber0to99() {
  const _num = Math.floor(Math.random() * 100).toString();
  return _num.length === 1 ? `0${_num}` : _num;
}

export function formatDayJsDateVN(data) {
  const _days = dayjs(data).format("DD");
  const _month = dayjs(data).format("MM");
  const _year = dayjs(data).format("YYYY");
  const _day = handleDayToString(data.$W);

  return `${_day}, ${_days}, tháng ${_month}, ${_year}`;
}

export function formatDayJsDateWithoutYearVN(data) {
  const _days = dayjs(data, "HH:mm DD/MM/YYYY").$D;
  const _month = dayjs(data, "HH:mm DD/MM/YYYY").$M + 1;
  const _day = handleDayToString(dayjs(data, "HH:mm DD/MM/YYYY").$W);
  return `${_day}, ${_days} tháng ${_month}`;
}
export function formatDayJsDateWithoutYearVNWeek(data) {
  const formattedDate = dayjs(data, "HH:mm DD/MM/YYYY");
  const hourMinute = formattedDate.format("HH:mm");
  const dayOfWeek = handleDayOfWeekToString(formattedDate.$W);
  const dayOfMonth = formattedDate.$D;
  const month = formattedDate.$M + 1;

  return `${hourMinute}, ${dayOfWeek}, ${dayOfMonth} thg ${month}`;
}
export const viLocale = {
  name: "vi",
  relativeTime: {
    future: "cách %s",
    past: "%s trước",
    s: "vài giây",
    m: "1 phút",
    mm: "%d phút",
    h: "1 giờ",
    hh: "%d giờ",
    d: "1 ngày",
    dd: "%d ngày",
    M: "1 tháng",
    MM: "%d tháng",
    y: "1 năm",
    yy: "%d năm",
  },
};
export const thLocale = {
  name: "th",
  relativeTime: {
    future: "หลัง %s",
    past: "%s ที่แล้ว",
    s: "ไม่กี่วินาที",
    m: "1 นาที",
    mm: "%d นาที",
    h: "1 ชั่วโมง",
    hh: "%d ชั่วโมง",
    d: "1 วัน",
    dd: "%d วัน",
    M: "1 เดือน",
    MM: "%d เดือน",
    y: "1 ปี",
    yy: "%d ปี",
  },
};
function handleDayOfWeekToString(dayOfWeek) {
  const daysOfWeek = ["CN", "Th 2", "Th 3", "Th 4", "Th 5", "Th6", "Th 7"];
  return daysOfWeek[dayOfWeek];
}
export function formatDayJsDateWithoutYearVNSMS(data) {
  const _day = handleDayToString(dayjs(data, "HH:mm DD/MM/YYYY").$W);
  return `${_day}`;
}
// export function formatDayJsDateWithoutYearELSMS(data) {
//   const _day = handleDayToStringEL(dayjs(data, "HH:mm DD/MM/YYYY").$W);
//   return `${_day}`;
// }
export function formatDayJsDateWithoutYearEL(data) {
  const _days = dayjs(data, "HH:mm DD/MM/YYYY").$D;
  const _month = dayjs(data, "HH:mm DD/MM/YYYY").format("MMMM");
  const _day = dayjs(data, "HH:mm DD/MM/YYYY").format("dddd");
  return `${_day}, ${_days} ${_month}`;
}
export function generateTransactionCode() {
  const randomNum1 = Math.floor(Math.random() * 1000000);
  const randomNum2 = Math.floor(Math.random() * 1000000);
  const randomNum3 = Math.floor(Math.random() * 1000000);

  const transactionCode = `${randomNum1.toString().padStart(6, "0")}.${randomNum2
    .toString()
    .padStart(6, "0")}.${randomNum3.toString().padStart(6, "0")}`;

  return transactionCode;
}
export function handleDayToString(data) {
  switch (data) {
    case 0:
      return "Chủ Nhật";
    case 1:
      return "Thứ Hai";
    case 2:
      return "Thứ Ba";
    case 3:
      return "Thứ Tư";
    case 4:
      return "Thứ Năm";
    case 5:
      return "Thứ Sáu";
    case 6:
      return "Thứ Bảy";

    default:
      break;
  }
}
export const listNhaMang = [
  {
    label: "Viettel",
    value: "Viettel",
  },
  {
    label: "MobiFone",
    value: "MobiFone",
  },
  {
    label: "Vinaphone",
    value: "VinaPhone",
  },
  {
    label: "Vietnamobile",
    value: "Vietnamobile",
  },
  {
    label: "Gmobile",
    value: "Gmobile",
  },
  {
    label: "VN Mobifone",
    value: "VN Mobifone",
  },
];
// export function handleDayToStringEL(data) {
//   switch (data) {
//     case 0:
//       return "Sunday";
//     case 1:
//       return "Monday";
//     case 2:
//       return "Tuesday";
//     case 3:
//       return "Wednesday";
//     case 4:
//       return "Thursday";
//     case 5:
//       return "Friday";
//     case 6:
//       return "Saturday";

//     default:
//       break;
//   }
// }

export function generateTransactionHash() {
  const length = 24;
  const characters = "abcdef0123456789";
  let hash = "";
  for (let i = 0; i < length; i++) {
    if (i > 0 && i % 4 === 0) {
      hash += "-";
    }
    hash += characters[Math.floor(Math.random() * characters.length)];
  }
  return "0x" + hash;
}

export const toCapitalize = (str) => {
  // input thứ 3,10 tháng 5, 2021
  // output Thứ 3,10 Tháng 5, 2021
  return str.toString().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};
export function IconSim(props) {
  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <div
          style={{
            backgroundColor: props.backgroundColor,
            height: ` ${props.columnHeight}px`,
            marginLeft: props.marginColumn,
            width: props.widthColumn,
            borderRadius: props.borderColumn,
          }}
        ></div>
        <div
          style={{
            backgroundColor: props.selectSim1 < 2 ? props.backgroundobscure : props.backgroundColor,
            height: ` ${props.columnHeight + props.columnHeightExtra * 1}px`,
            marginLeft: props.marginColumn,
            width: props.widthColumn,
            borderRadius: props.borderColumn,
          }}
        ></div>
        <div
          style={{
            backgroundColor: props.selectSim1 < 3 ? props.backgroundobscure : props.backgroundColor,
            height: ` ${props.columnHeight + props.columnHeightExtra * 2}px`,
            marginLeft: props.marginColumn,
            width: props.widthColumn,
            borderRadius: props.borderColumn,
          }}
        ></div>
        <div
          style={{
            backgroundColor: props.selectSim1 < 4 ? props.backgroundobscure : props.backgroundColor,
            height: ` ${props.columnHeight + props.columnHeightExtra * 3}px`,
            width: props.widthColumn,
            marginLeft: props.marginColumn,
            borderRadius: props.borderColumn,
          }}
        ></div>
      </div>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <div
          style={{
            backgroundColor: props.backgroundColor,
            marginLeft: props.marginColumn,
            width: props.widthColumn,
            borderRadius: props.borderColumn,
            height: ` ${props.columnHeight}px`,
          }}
        ></div>
        <div
          style={{
            backgroundColor: props.selectSim2 < 2 ? props.backgroundobscure : props.backgroundColor,
            marginLeft: props.marginColumn,
            width: props.widthColumn,
            borderRadius: props.borderColumn,
            height: ` ${props.columnHeight}px`,
          }}
        ></div>
        <div
          style={{
            backgroundColor: props.selectSim2 < 3 ? props.backgroundobscure : props.backgroundColor,
            marginLeft: props.marginColumn,
            width: props.widthColumn,
            borderRadius: props.borderColumn,
            height: ` ${props.columnHeight}px`,
          }}
        ></div>
        <div
          style={{
            backgroundColor: props.selectSim2 < 4 ? props.backgroundobscure : props.backgroundColor,
            marginLeft: props.marginColumn,
            width: props.widthColumn,
            borderRadius: props.borderColumn,
            height: ` ${props.columnHeight}px`,
          }}
        ></div>
      </div>
    </>
  );
}
export function RandomizeVCBNumber() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVY";
  const getRandomLetter = () => {
    const randomIndex = Math.floor(Math.random() * characters.length);
    return characters.charAt(randomIndex);
  };
  const randomLetters = getRandomLetter();
  const randomPart = Math.floor(Math.random() * 10000000000);
  const randomPart2 = Math.floor(Math.random() * 1000000)
    .toString()
    .padStart(6, "0");
  const result = randomLetters + "BVCB." + randomPart + "." + randomPart2;
  return result;
}
export function formatCurrency(value) {
  if (!value) {
    return "";
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const generateRandomNumber = () => {
  const randomNumber = Math.floor(Math.random() * 15) + 1;

  return randomNumber === 0 ? 1 : randomNumber;
};
export const convertNumber = (number) => {
  if (number.length <= 5) return number;
  const visibleDigits = number.substring(0, 2);
  const visibleDigitsEnd = number.substring(5);
  const hiddenDigits = "xxx";
  const visiblePart = `${visibleDigits}${hiddenDigits}`;
  return `${visiblePart}${visibleDigitsEnd}`;
};
