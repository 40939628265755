import React, { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import styles from "./style.module.css";
import "./dotant.css";
import { Timeline, Image, Spin } from "antd";
import logo from "../../assets/images/logo-no-avata.png";
import { getnotification } from "../../helpers/helper";
import { thLocale } from "../../common/function";

// eslint-disable-next-line no-unused-vars
import { BankOutlined } from "@ant-design/icons";
dayjs.extend(relativeTime);
export default function DashboardPage() {
  const [firstRender, setFirstRender] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [loadingTotalBill, setLoadingTotalBill] = useState(false);
  const [notification, setListNotifi] = useState([]);
  // const [totalbill, setLTotalBill] = useState(null);
  // const infoUsers = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
  const getlistNotifi = async () => {
    setLoading(true);
    const bankItemRes = await getnotification();
    if (bankItemRes) {
      setListNotifi(bankItemRes);
      setLoading(false);
    }
  };
  // const getTotaCountlBill = async (data) => {
  //   setLoadingTotalBill(true);
  //   const userId = infoUsers ? infoUsers.id : "";
  //   const totalBill = await getTotalBill(userId);
  //   if (totalBill) {
  //     setLTotalBill(totalBill.totalCount);
  //     setLoadingTotalBill(false);
  //   }
  // };
  React.useEffect(() => {
    if (!firstRender) {
      getlistNotifi();
      setFirstRender(true);
      // getTotaCountlBill();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);
  function formatTimeAgo(createdDate) {
    return dayjs(createdDate).locale(thLocale).fromNow();
  }
  return (
    <>
      {/* <Spin spinning={loadingTotalBill}>
        <div className={styles.divdashboard}>
          <div className={styles.itemDiv}>
            <span>{totalbill}</span>
            <span>Tổng bill đã tạo</span>
            <span className={styles.iconBank}>
              <BankOutlined />
            </span>
          </div>
        </div>
      </Spin> */}
      <Spin spinning={loading}>
        <div className={styles.backgroundWrapper}>
          <div className={styles.notifi}>
            <span>การแจ้งเตือนจากระบบ</span>
          </div>
          {notification && notification.length > 0 && (
            <Timeline
              items={notification.map((item) => ({
                dot: <Image className={styles.logoBill} src={logo} alt="icon" />,
                children: (
                  <>
                    <span className={styles.spantime}>{formatTimeAgo(item.createdDate)}</span>
                    <div className={styles.timelineItem}>{item.content}</div>
                  </>
                ),
              }))}
            />
          )}
        </div>
      </Spin>
    </>
  );
}
