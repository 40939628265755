import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Input, Col, Row, notification, Typography, Space, Divider, Table, message } from "antd";
import { getIpAddress, login } from "../../helpers/helper";
import img_login from "../../assets/images/img-signin.png";
import bg from "../../assets/images/cover-pattern.png";
import logo from "../../assets/images/logo-no-background.png";
import { FingerPrintContext } from "../../hooks/fingerPrintContext";
import { CopyOutlined } from "@ant-design/icons";
const { Title } = Typography;

export default function LoginPage() {
  document.title = "Đăng nhập";

  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const { fingerPrint, userData } = useContext(FingerPrintContext);
  const [ip, setIp] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      return window.location.replace("/");
    }
    getIpAddress().then((result) => {
      setIp(result);
    });
  }, []);

  const onFinish = async (values) => {
    // const _req = {
    //   username: values.username,
    //   password: values.password,
    // };
    const _req = {
      username: values.username,
      password: values.password,
      fingerPrint: fingerPrint,
      // visitorId: fingerPrint,
      // ip: userData?.ip,
      // countryCode: userData?.countryCode,
      // device: userData?.device,
      // browser: userData?.browser,
    };
    try {
      setLoading(true);
      const res = await login(_req);
      if (res.status !== 200) {
        return api["error"]({
          message: "Lỗi",
          description: res.message,
        });
      }
      localStorage.setItem("user", JSON.stringify(res?.data?.logedInUser));
      const token = res.data?.token;
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      return window.location.replace("/");
    } catch (e) {
      return api["error"]({
        message: "Lỗi",
        description: "Đăng nhập thất bại",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = (record) => {
    record = `FingerprintId: ${record.fp} - IP: ${record.ip}`;
    navigator.clipboard.writeText(record);
    message.success("Đã copy vào clipboard");
  };

  const columns = [
    {
      key: "info",
      title: "Thông tin",
      dataIndex: "info",
      render: (text, record) => (
        <Space direction="vertical" size="small">
          <p>FingerprintId: {record.fp}</p>
          <p>IP: {record.ip}</p>
        </Space>
      ),
    },
    {
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <Space>
          <Button type="primary" icon={<CopyOutlined />} onClick={() => handleCopy(record)}></Button>
        </Space>
      ),
    },
  ];

  const dataSources = [
    {
      fp: fingerPrint,
      ip: ip,
    },
  ];
  return (
    <Row
      align="middle"
      justify="center"
      gutter={[16, 16]}
      className="login-pages"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <Row className="container-login" align="middle" justify="center" gutter={[16, 16]}>
        {contextHolder}
        <Col span={13} className="img-login">
          <img src={img_login} alt="" />
        </Col>
        <Col span={8} className="box-login">
          <Row>
            <Col span={24} justify="center" align="middle">
              <img src={logo} style={{ width: 300 }} alt="" />
            </Col>
          </Row>

          <Title style={{ display: "flex", justifyContent: "center" }} level={2}>
            เข้าสู่ระบบ
          </Title>
          <Form
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
              fingerPrint: fingerPrint,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="ชื่อผู้ใช้"
              name="username"
              rules={[
                {
                  required: true,
                  message: "กรุณาใส่ชื่อผู้ใช้!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="รหัสผ่าน"
              name="password"
              rules={[
                {
                  required: true,
                  message: "กรุณาใส่รหัสผ่าน!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            {/*<Form.Item*/}
            {/*  label="FP của bạn"*/}
            {/*  name="fingerPrint"*/}

            {/*>*/}
            {/*  <Space.Compact style={{ width: '100%' }}>*/}
            {/*    <Input defaultValue={fingerPrint} disabled/>*/}
            {/*    <Button type="primary" icon={<CopyOutlined />}></Button>*/}
            {/*  </Space.Compact>*/}
            {/*</Form.Item>*/}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading ? true : false}
                style={{ width: "100%", textTransform: "uppercase" }}
              >
                เข้าสู่ระบบ
              </Button>
            </Form.Item>
            {/* <Divider>ข้อมูลการเข้าสู่ระบบของคุณ</Divider>
            <Table columns={columns} dataSource={dataSources} pagination={false} /> */}
          </Form>
        </Col>
      </Row>
    </Row>
  );
}
