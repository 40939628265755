export const textConfirmDelete = "Bạn có chắc chắn muốn xóa?";
export const NAME_VIETCOMBANK = "Vietcombank";
export const NAME_TECHCOMBANK = "Techcombank";
export const NAME_TPBANK = "TPBank";
export const NAME_ACB = "ACB";
export const NAME_MBBANK = "Mbbank";
export const NAME_MOMO = "Momo";
export const NAME_VIETINBANK = "Vietinbank";
export const NAME_AGRIBANK = "Agribank";
export const NAME_BIDV = "BIDV";
export const NAME_SACOMBANK = "SACOMBANK";
export const NAME_VPBANK = "Vpbank";
export const NAME_MSB = "Bankok Bank";
export const NAME_DONGA = "Krungthai";
export const NAME_KEPT = "Kept By Krungsri";
export const NAME_KBANK = "KBank";
export const NAME_TTB = "TTB Bank";
export const NAME_SCB = "SCB Bank";
export const NAME_Agriculture = "ธกส";
export const NAME_sivi = "ธนาคารออมสิน";
export const NAME_K = "K+";
export const NAME_LH = "LH Bank";
export const NAME_KB = "KBankVN";
export const NAME_banking = "banking";
export const NAME_CIMB = "CIMB";
export const NAME_GHBANK = "GHBANK";
export const NAME_MAKE = "MAKEBYKBANK";
export const NAME_TRUEMONEY = "TRUEMONEY";

export const NAME_OCB_BANK = "Krungsri";

export const ADMIN_ROLE = "Admin";

export const ROUTE_KEY = {
  JUN88: "Jun88",
  "789BET": "789BET",
  HI88: "Hi88",
  F8BET: "F8BET",
  SHBET: "SHBET",
  NEW88: "NEW88",
};
export const GAME_KEY = {
  JUN88: "JUN88",
  "789BET": "789BET",
  HI88: "HI88",
  F8BET: "F8BET",
  SHBET: "SHBET",
  NEW88: "NEW88",
  VIETCOMBANK: "VCB",
};
export const GAME_KEY_APP = {
  "VIETCOMBANKAPP APP": "VIETCOMBANKAPP",
  BIDV: "BIDV",
  Techcombank: "TECHCOMBANK",
};
export const GAME_KEY_LIST = Object.values(GAME_KEY);
export const GAME_KEY_LIST_APP = Object.values(GAME_KEY_APP);
