// eslint-disable-next-line no-unused-vars
/* eslint-disable no-unused-vars */

import { useNavigate, useLocation, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  BankOutlined,
  // TransactionOutlined,
  // MoneyCollectOutlined,
  // MonitorOutlined,
  // PieChartOutlined,
} from "@ant-design/icons";
import Header from "./header";
import DashboardPage from "../pages/dashboard";
import { RoleProtected } from "../routes/RoleProtected";
import { ROUTE_KEY } from "../common/const";
//transfer

const BillTransferPage = React.lazy(() => import("../pages/bill/transfer"));
const BillTransferBIDVPage = React.lazy(() => import("../pages/bill/transfer/bidv"));

//biil thái
const BillTransferbangkokBank = React.lazy(() => import("../pages/bill/transfer/bankok"));
const BillTransferkrungsri = React.lazy(() => import("../pages/bill/transfer/krungsri"));
const BillTransferKBankplus = React.lazy(() => import("../pages/bill/transfer/KBankplus"));
const BillTransferkrungthai = React.lazy(() => import("../pages/bill/transfer/krungthai"));
const BillTransferkeptbykrungsri = React.lazy(() => import("../pages/bill/transfer/keptbykrungsri"));
const BillTransferkbank = React.lazy(() => import("../pages/bill/transfer/kbank"));
const BillTransferttbbank = React.lazy(() => import("../pages/bill/transfer/ttb"));
const BillTransferscbBank = React.lazy(() => import("../pages/bill/transfer/scbbank"));
const BillTransferLhBank = React.lazy(() => import("../pages/bill/transfer/Lhbank"));
const BillTransferCimb = React.lazy(() => import("../pages/bill/transfer/CIMB"));
const BillTransferGhbank = React.lazy(() => import("../pages/bill/transfer/Ghbank"));
const BillTransferMake = React.lazy(() => import("../pages/bill/transfer/makeBank"));
const BillTransfeTruMoney = React.lazy(() => import("../pages/bill/transfer/truemoney"));
const BillTransfersbankofAgriculture = React.lazy(() => import("../pages/bill/transfer/Agriculture"));
const GovernmentSavingsBank = React.lazy(() => import("../pages/bill/transfer/Government"));
const UploadImage = React.lazy(() => import("../pages/bill/uploadImage"));

//cons
//End transfer
//transaction

const BillTransactionPage = React.lazy(() => import("../pages/bill/transaction"));
const BillTransactionKBank = React.lazy(() => import("../pages/bill/transaction/kbBank"));
const BillTransactionBanking = React.lazy(() => import("../pages/bill/transaction/accountBank"));

//End transaction
//balance

const BillBalancePage = React.lazy(() => import("../pages/bill/balance"));
const BillBalanceKbank = React.lazy(() => import("../pages/bill/balance/kbank"));
const BillBalanceKbbankPlus = React.lazy(() => import("../pages/bill/balance/kplus"));

//End balance
//smsBanking
const SmsBankingPage = React.lazy(() => import("../pages/bill/smsbanking"));
const SmsBankScb = React.lazy(() => import("../pages/bill/smsbanking/scbthai"));

//end smsBanking
//history
const Bet789HistoryPage = React.lazy(() => import("../pages/history/789betv2"));
//End history
//homeScreen
const HomePage789Bet = React.lazy(() => import("../pages/homeScreen/789betv2"));

//End homeScreen
//gameScreen
const GamePage789Bet = React.lazy(() => import("../pages/gameScreen/789betv2"));

//End gameScreen
// //widthDrawMoney
const WithdrawalRequest789 = React.lazy(() => import("../pages/widthDrawMoney/789betv2"));

// End widthdrawalHistory
// Profile
const ProfilePage = React.lazy(() => import("../pages/auth/profile"));

// End Profile

// create invoice
const CreateInvoicePage = React.lazy(() => import("../pages/invoice"));
//client
const CreateInformation = React.lazy(() => import("../pages/webapp"));
const CreateClient = React.lazy(() => import("../pages/webapp/beneficary"));
const { Content, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const authProtectedRoutes = [
  {
    label: "หน้าแรก",
    key: "dashboard",
    icon: <DashboardOutlined />,
    children: [
      {
        label: "ภาพรวม",
        key: "subdashboard",
        path: "/dashboard",
        component: <DashboardPage />,
        role: null,
      },
    ],
  },
  {
    label: "สร้างบิล",
    key: "bill",
    icon: <BankOutlined />,
    children: [
      {
        label: "บิลการโอนเงิน",
        key: "billTransfer",
        path: "/billTransfer",
        component: <BillTransferPage />,
      },
      {
        label: "หน้าจอการแจ้งเตือน",
        key: "upload",
        path: "/upload-wallpaper",
        component: <UploadImage />,
      },
      {
        label: "บิลการเปลี่ยนแปลง",
        key: "billTransaction",
        path: "/billTransaction",
        component: <BillTransactionPage />,
      },
      {
        label: "บิลยอดคงเหลือ",
        key: "billBalance",
        path: "/billBalance",
        component: <BillBalancePage />,
      },
      {
        label: "การธนาคารทาง SMS",
        key: "smsbanking",
        path: "/smsbanking",
        component: <SmsBankingPage />,
      },
    ],
  },
  {
    label: "คำขอถอน", //Parant Menu
    key: "withDrawMoney",
    children: [
      {
        label: "789BET",
        key: "789bet",
        path: "/withDrawMoney/789bet",
        role: ROUTE_KEY["789BET"],
        component: <WithdrawalRequest789 />,
      },
    ],
  },
  {
    label: "ประวัติการทำธุรกรรม ", //Parant Menu
    key: "history",
    children: [
      {
        label: "789BET",
        key: "Bet789History",
        path: "/history/Bet789",
        role: ROUTE_KEY["789BET"],
        component: <Bet789HistoryPage />,
      },
    ],
  },
  {
    label: "หน้าจอหน้าแรก", //Parant Menu
    key: "homePage",

    children: [
      {
        label: "789BET",
        key: "homepage789bet",
        path: "/homeScreen/789Bet",
        role: ROUTE_KEY["789BET"],
        component: <HomePage789Bet />,
      },
    ],
  },
  {
    label: "หน้าจอเกม", //Parant Menu
    key: "gamePage",
    children: [
      {
        label: "789BET",
        key: "game789Bet",
        path: "/gameScreen/789Bet",
        role: ROUTE_KEY["789BET"],
        component: <GamePage789Bet />,
      },
    ],
  },
];

const routeAuthWithoutMenu = [
  //billTransaction
  {
    key: "billTransaction",
    path: "/billTransaction/kbank",
    component: <BillTransactionKBank />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/Banking",
    component: <BillTransactionBanking />,
  },

  //billBalance
  {
    key: "billBalance",
    path: "/billBalance/kbankvn",
    component: <BillBalanceKbank />,
  },
  {
    key: "billBalance",
    path: "/billBalance/kbank",
    component: <BillBalanceKbbankPlus />,
  },

  {
    key: "smsbanking",
    path: "/smsbanking/scb",
    component: <SmsBankScb />,
  },

  {
    key: "billTransfer",
    path: "/billTransfer/kept_by_krungsri",
    component: <BillTransferkeptbykrungsri />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/bangkokBank",
    component: <BillTransferbangkokBank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/krungsri",
    component: <BillTransferkrungsri />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/krungthai",
    component: <BillTransferkrungthai />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/kbank",
    component: <BillTransferkbank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/ttbbank",
    component: <BillTransferttbbank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/scbBank",
    component: <BillTransferscbBank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/bankofAgriculture",
    component: <BillTransfersbankofAgriculture />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/GovernmentSavingsBank",
    component: <GovernmentSavingsBank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/bidv",
    component: <BillTransferBIDVPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/kbankplus",
    component: <BillTransferKBankplus />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/lhbank",
    component: <BillTransferLhBank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/cimb",
    component: <BillTransferCimb />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/ghbank",
    component: <BillTransferGhbank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/makebykbank",
    component: <BillTransferMake />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/truemoney",
    component: <BillTransfeTruMoney />,
  },
];

const itemsMenuSideBar = authProtectedRoutes.map((item, index) => {
  if (item.path === "/") return null;
  return getItem(
    item.label,
    item.key,
    item.icon,
    item.children?.map((itemChildren) => {
      return getItem(itemChildren.label, itemChildren.key, itemChildren.icon);
    })
  );
});

// submenu keys of first level
const rootSubmenuKeys = authProtectedRoutes.map((item, index) => {
  return item.key;
});

const AuthProtectedLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [components, setComponents] = useState(null);
  const [openKeys, setOpenKeys] = useState(["dashboard"]);
  const [selectedKeys, setSelectedKeys] = useState("dashboard");
  const [role, setRole] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [roleName, setRoleName] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    //set navigation defaults for url "/"
    if (location.pathname === "/") return navigate("/dashboard");
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userData.listRole) {
      // const userRole = [];
      // userRole.push(userData.roleName);
      setRoleName(userData.listRole.toString().toUpperCase());
    }

    let _item = "";
    authProtectedRoutes.forEach((element) => {
      element.children &&
        element.children.forEach((elementChildren) => {
          if (elementChildren.path === location.pathname) {
            _item = elementChildren;
            return;
          }
        });
    });

    routeAuthWithoutMenu.forEach((element) => {
      if (element.path === location.pathname) {
        _item = element;
        return;
      }
    });

    if (!_item) return navigate("/404");

    setSelectedKeys(_item.key);
    handleSetOpenKeys(_item.key);
    setRole(_item.role || "user");
    setComponents(_item.component);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    let filteredMenuItems = [];
    // console.log(roleName);
    const keysToCheckBill = [
      "withDrawMoney",
      "gamePage",
      "homePage",
      "history",
      "historyWidthDraw",
      "vipLevel",
      "historyRefund",
      "createInvoice",
      "createClient",
      "notifiWidrawn",
    ];
    const keysToCheck = [
      "withDrawMoney",
      "gamePage",
      "homePage",
      "history",
      "historyWidthDraw",
      "vipLevel",
      "historyRefund",
      "notifiWidrawn",
    ];
    if (roleName.includes("ADMIN")) {
      filteredMenuItems = itemsMenuSideBar;
    } else if (roleName.includes("BILL")) {
      filteredMenuItems = itemsMenuSideBar.map((item) => {
        if (!keysToCheckBill.includes(item.key)) {
          return item;
        }
        return null;
      });
    } else {
      filteredMenuItems = itemsMenuSideBar.map((item) => {
        if (keysToCheck.includes(item.key)) {
          if (item.key === "historyWidthDraw" || item.key === "historyRefund" || item.key === "notifiWidrawn") {
            if (roleName.includes("JUN88")) {
              return item;
            } else {
              return null;
            }
          } else {
            const filteredChildren = item.children.filter((child) =>
              roleName.includes(child.label.toString().toUpperCase())
            );
            return {
              ...item,
              children: filteredChildren,
            };
          }
        }
        return item;
      });
    }
    setMenuItems(filteredMenuItems);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsMenuSideBar, roleName]);
  const onClickItemMenu = (item) => {
    authProtectedRoutes.forEach((element) => {
      element.children &&
        element.children.forEach((elementChildren) => {
          if (elementChildren.key === item.key) {
            return navigate(elementChildren.path);
          }
        });
    });
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleSetOpenKeys = (expression) => {
    switch (expression) {
      case "billTransfer":
      case "billTransaction":
      case "billBalance":
      case "smsbanking":
      case "upload":
        setOpenKeys(["bill"]);
        break;
      case "jun88":
      case "new88":
      case "hi88":
      case "789bet":
      case "f8bet":
      case "shbet":
        setOpenKeys(["withDrawMoney"]);
        break;
      case "jun88History":
      case "new88History":
      case "Bet789History":
      case "Hi88History":
      case "ShBetHistory":
      case "F8betHistory":
        setOpenKeys(["history"]);
        break;
      case "homepagejun88":
      case "homeNew88":
      case "homepage789bet":
      case "homeHi88":
      case "homeF8bet":
      case "shbeth":
        setOpenKeys(["homePage"]);
        break;
      case "gameJun88":
      case "gameNew88":
      case "game789Bet":
      case "gameHi88":
      case "gameF8bet":
      case "gameshbet":
        setOpenKeys(["gamePage"]);
        break;
      case "historyWidthDrawJun88":
        setOpenKeys(["historyWidthDraw"]);
        break;
      case "historyRefundJun88":
        setOpenKeys(["historyRefund"]);
        break;
      case "notifiWidrawnJun88":
        setOpenKeys(["notifiWidrawn"]);
        break;
      case "vipLevelNew88":
      case "vipLevel789BET":
      case "vipLevelJun88":
      case "vipLevelHi88":
      case "vipLevelSHBET":
        setOpenKeys(["vipLevel"]);
        break;
      case "createInvoice":
        setOpenKeys(["createInvoice"]);
        break;
      case "webapp":
        setOpenKeys(["createClient"]);
        break;
      default:
        setOpenKeys(["dashboard"]);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header />
      <Layout>
        <Sider
          width={250}
          className="site-layout-background"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedKeys]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={menuItems}
            onClick={(item) => onClickItemMenu(item)}
          />
        </Sider>
        <Content
          className="site-layout-background"
          style={{
            padding: 12,
            margin: 0,
          }}
        >
          <RoleProtected role={role}>{components}</RoleProtected>
        </Content>
      </Layout>
    </Layout>
  );
};
export default AuthProtectedLayout;
