import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ConfigProvider } from "antd";
import thTH from "antd/locale/th_TH"; // Nhập ngôn ngữ tiếng Thái

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider locale={thTH}>
    <App />
  </ConfigProvider>
);
